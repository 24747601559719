import Vue from 'vue';
import Vuex from 'vuex';
import auth from './modules/auth/index';
import users from './modules/users/index';

Vue.use(Vuex);

const modules = {
  auth,
  users,
};

export default new Vuex.Store({
  modules,
});
