import Vue from 'vue';
import Router from 'vue-router';

// Pages
import Login from '@/components/pages/home/login/index.vue';
import Webapp from '@/components/pages/webapp/index.vue';
import Dashboard from '@/components/pages/webapp/dashboard/index.vue';
import Preco from '@/components/pages/webapp/novo/index.vue';
import ForgotPassword from '@/components/pages/home/send-code/index.vue';
import changePassword from '@/components/pages/home/change-password/index.vue';

Vue.use(Router);

export default new Router({
  mode: 'history',
  history: true,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Login,
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
    },
    {
      path: '/forgot-password',
      name: 'ForgotPassword',
      component: ForgotPassword,
    },
    {
      path: '/change-password/:email',
      name: 'ChangePassword',
      component: changePassword,
    },
    {
      path: '/app',
      name: 'webapp',
      component: Webapp,
      children: [
        {
          path: '/dashboard',
          name: 'dashboard',
          component: Dashboard,
        },
        {
          path: '/preco',
          name: 'preco',
          component: Preco,
        },
      ],
    },
  ],
});
