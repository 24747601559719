<template>
  <svg
    width="7rem"
    height="7rem"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
    class="lds-ripple"
  >
    <circle
      cx="50"
      cy="50"
      r="39.9744"
      fill="none"
      ng-attr-stroke="2px"
      ng-attr-stroke-width="30"
      stroke="#3f50f6"
      stroke-width="2"
    >
      <animate
        attributeName="r"
        calcMode="spline"
        values="0;40"
        keyTimes="0;1"
        dur="1"
        keySplines="0 0.2 0.8 1"
        begin="-0.5s"
        repeatCount="indefinite"
      ></animate>
      <animate
        attributeName="opacity"
        calcMode="spline"
        values="1;0"
        keyTimes="0;1"
        dur="1"
        keySplines="0.2 0 0.8 1"
        begin="-0.5s"
        repeatCount="indefinite"
      ></animate>
    </circle>
    <circle
      cx="50"
      cy="50"
      r="25.5326"
      fill="none"
      ng-attr-stroke="2px"
      ng-attr-stroke-width="30"
      stroke="#694cfe"
      stroke-width="2"
    >
      <animate
        attributeName="r"
        calcMode="spline"
        values="0;40"
        keyTimes="0;1"
        dur="1"
        keySplines="0 0.2 0.8 1"
        begin="0s"
        repeatCount="indefinite"
      ></animate>
      <animate
        attributeName="opacity"
        calcMode="spline"
        values="1;0"
        keyTimes="0;1"
        dur="1"
        keySplines="0.2 0 0.8 1"
        begin="0s"
        repeatCount="indefinite"
      ></animate>
    </circle>
  </svg>
</template>

<script>
export default {
  name: 'Loader',
};
</script>

<style>
</style>
