<template>
  <div>
    <b-navbar
      toggleable="lg"
      type="light"
      variant="light"
    >
      <!-- <b-navbar-brand
        href="#"
        :style="menuColapsed"
      >

      </b-navbar-brand> -->

      <button
        @click="menu"
        class="btn btn-outline-primary"
      >
        <i class="fas fa-bars"></i>
      </button>
      <b-navbar-nav>
        <!-- <div
          class="sidebartoggler"
          @click="colapseMenu"
        >
          <div class="line-1"></div>
          <div class="line-2"></div>
          <div class="line-3"></div>
        </div> -->
      </b-navbar-nav>

      <!-- <b-navbar-toggle target="nav-collapse"></b-navbar-toggle> -->

      <b-collapse
        id="nav-collapse"
        is-nav
      >
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-nav-item-dropdown right>
            <!-- Using 'button-content' slot -->
            <template v-slot:button-content>
              <b-img
                style="width: 40px;"
                src="https://api.bi-assobens.com.br/v1/public/profile.png"
                rounded="circle"
                alt="Profile"
              ></b-img>
            </template>
            <b-dropdown-item v-b-modal.modal-password>Alterar Senha</b-dropdown-item>
            <b-dropdown-item @click="shutdown">Sair</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <modal-change-password />
  </div>
</template>

<script>
import EventBus from '../../../eventBus';
import ModalChangePassword from '../modal-change-password/index.vue';

export default {
  name: 'Navbar',
  // eslint-disable-next-line
  components: { ModalChangePassword },
  data: () => ({
    userAuth: null,
    collapse: true,
    menuColapsed: {
      display: null,
    },
    mainProps: {
      blank: true,
      blankColor: '#777',
      width: 75,
      height: 75,
      class: 'm1',
    },
  }),
  methods: {
    // update
    async menu() {
      EventBus.$emit('collapse', !this.collapse);
      this.collapse = !this.collapse;

      if (this.menuColapsed.display === 'none') {
        this.menuColapsed.display = null;
        EventBus.$emit('colapse-menu', '260px');
      } else {
        this.menuColapsed.display = 'none';
        EventBus.$emit('colapse-menu', '60px');
      }
    },
    async shutdown() {
      localStorage.setItem('_pbiDadosVan', null);
      this.$router.push('/login');
    },
  },
  created() {
    this.userAuth = JSON.parse(localStorage.getItem('_pbiDadosVan'));
  },
};
</script>

<style lang="scss" scoped>
.bg-light {
  background-color: #f1f1f1 !important;
  border-bottom: 1px solid #ccc;
}
</style>
