<template>
  <div>
    <report-pbi
      report-id="95bf1888-e46a-4a4c-91bb-a9f9e87cc905"
      group-id="5de7d4a7-b0d1-41a8-a978-693e831cfdcd"
    ></report-pbi>
  </div>
</template>

<script>
import reportPbi from '@/components/organisms/report-pbi/index.vue';

export default {
  components: {
    reportPbi,
  },
};
</script>
<style lang="scss" scoped>
.wrapper {
  background-color: #fff;
  width: 100px;
}
</style>
