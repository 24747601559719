import actions from './actions';
import mutations from './mutations';
import getters from './getters';
import initialState from './initialState';

export default {
  namespaced: true,
  actions,
  mutations,
  getters,
  state: {
    ...initialState,
  },
};
