<template>
  <div>
    <report-pbi
      report-id="1b735498-1ecf-46fc-ab25-8d1b1ff81e3d"
      group-id="5de7d4a7-b0d1-41a8-a978-693e831cfdcd"
    ></report-pbi>
  </div>
</template>

<script>
import reportPbi from '@/components/organisms/report-pbi/index.vue';

export default {
  components: {
    reportPbi,
  },
};
</script>
<style lang="scss" scoped>
.wrapper {
  background-color: #fff;
  width: 100px;
}
</style>
