<template>
  <div class="wrapper-login">
    <div class="bg-color"></div>
    <div class="container">
      <div class="row">
        <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">
          <div class="card card-signin my-5">
            <div class="card-body">
              <form class="form-signin">
                <div class="form-label-group">
                  <input
                    v-model="email"
                    type="email"
                    id="inputEmail"
                    class="form-control"
                    required
                    autofocus
                  />
                  <label for="inputEmail">Informe seu e-mail</label>
                </div>
                <button
                  @click="send"
                  class="btn btn-lg btn-primary btn-block text-uppercase"
                  type="button"
                >
                  Enviar
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../../../../services/api';

export default {
  name: 'ForgotPassword',
  data: () => ({
    email: null,
  }),
  methods: {
    async send() {
      try {
        await api.post('/forgot-password', { email: this.email });
        this.$router.push(`/change-password/${this.email}`);
      } catch (error) {
        window.console.log(error);
        this.$swal(
          'Erro!',
          'Confirme o email informado e tente novamente.',
          'error',
        );
      }
    },
  },
};
</script>

<style>
:root {
  --input-padding-x: 1.5rem;
  --input-padding-y: 0.75rem;
}

.forgot-password a {
  color: black;
}
.wrapper-login {
  /* background: #3f50f6; */
  /* background: linear-gradient(to right, #3f50f6, #694cfe); */
  background: url('../../../../assets/mercedes_vans.jpg');
  min-height: 100vh;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  overflow: auto;
}

.card-signin {
  border: 0;
  border-radius: 1rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  /* transform: translateX(-50%); */
  /* box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1); */
}

.card-signin .card-title {
  margin-bottom: 2rem;
  font-weight: 300;
  font-size: 1.5rem;
}

.card-signin .card-body {
  padding: 2rem;
}

.form-signin {
  width: 100%;
}

.form-signin .btn {
  font-size: 80%;
  border-radius: 5rem;
  letter-spacing: 0.1rem;
  font-weight: bold;
  padding: 1rem;
  transition: all 0.2s;
}

.form-label-group {
  position: relative;
  margin-bottom: 1rem;
}

.form-label-group input {
  height: auto;
  border-radius: 2rem;
}

.form-label-group > input,
.form-label-group > label {
  padding: var(--input-padding-y) var(--input-padding-x);
}

.form-label-group > label {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  margin-bottom: 0;
  /* Override default `<label>` margin */
  line-height: 1.5;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: all 0.1s ease-in-out;
}

.form-label-group input::-webkit-input-placeholder {
  color: transparent;
}

.form-label-group input:-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-moz-placeholder {
  color: transparent;
}

.form-label-group input::placeholder {
  color: transparent;
}

.form-label-group input:not(:placeholder-shown) {
  padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));
  padding-bottom: calc(var(--input-padding-y) / 3);
}

.form-label-group input:not(:placeholder-shown) ~ label {
  padding-top: calc(var(--input-padding-y) / 3);
  padding-bottom: calc(var(--input-padding-y) / 3);
  font-size: 12px;
  color: #777;
}

.btn-google {
  color: white;
  background-color: #ea4335;
}

.btn-facebook {
  color: white;
  background-color: #3b5998;
}

/* Fallback for Edge
-------------------------------------------------- */

@supports (-ms-ime-align: auto) {
  .form-label-group > label {
    display: none;
  }
  .form-label-group input::-ms-input-placeholder {
    color: #777;
  }
}

/* Fallback for IE
-------------------------------------------------- */

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .form-label-group > label {
    display: none;
  }
  .form-label-group input:-ms-input-placeholder {
    color: #777;
  }
}

.card {
  /* background-color: transparent !important; */
  background-color: transparent !important;
  border: none !important;
}
.bg-color {
  position: fixed;
  top: 0;
  left: 0;
  width: auto;
  height: auto;
  min-width: 100%;
  min-height: 100%;
}
</style>
