import types from './mutations-types';

const mutations = {
  [types.SET_JWT](state, payload) {
    state.jwt = payload.login;
  },
  [types.SET_EMAIL](state, payload) {
    state.email = payload;
  },
};

export default mutations;
