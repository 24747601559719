<template>
  <b-modal
    id="modal-password"
    title="Alterar Senha"
    ok-title="Salvar"
    cancel-title="Cancelar"
    @ok="send"
  >
    <div class="form-group">
      <label for="current-password">Senha atual:</label>
      <input
        type="password"
        v-model="currentPassword"
        id="current-password"
        class="form-control"
        placeholder="Senha atual"
      />
      <div class="invalid-feedback">
        Por favor, informe a senha atual.
      </div>
    </div>
    <div class="form-group">
      <label for="new-password">Nova senha:</label>
      <input
        type="password"
        v-model="newPassword"
        id="new-password"
        class="form-control"
        placeholder="Nova senha"
      />
      <div class="invalid-feedback">
        Por favor, informe a nova senha.
      </div>
    </div>
    <div class="form-group">
      <label for="new-password2">Repita a nova senha:</label>
      <input
        type="password"
        v-model="newPassword2"
        id="new-password2"
        class="form-control"
        placeholder="Nova senha"
      />
      <div class="invalid-feedback">
        Os valores devem ser iguais.
      </div>
    </div>
  </b-modal>
</template>

<script>
import api from '../../../services/api';

export default {
  data: () => ({
    userAuth: null,
    currentPassword: null,
    newPassword: null,
    newPassword2: null,
  }),

  created() {
    this.userAuth = JSON.parse(localStorage.getItem('_pbiDadosVan'));
  },

  methods: {

    async send(event) {
      if (this.validateFields()) {
        try {
          await api.put(`/user/${this.userAuth.id}`, {
            currentPassword: this.currentPassword,
            password: this.newPassword,
          });
          this.$swal(
            'Senha Alterada!',
            'Sua senha foi alterada com sucesso!',
            'success',
          );
        } catch (error) {
          if (error.response) {
            this.$swal(
              'Oops!',
              // 'Verifique seus dados e tente novamente.',
              error.response.data.message,
              'error',
            );
          }
        }
      } else {
        event.preventDefault();
      }
    },

    validateFields() {
      if (!this.currentPassword) {
        document.getElementById('current-password').classList.add('is-invalid');
        return false;
      }
      if (!this.newPassword) {
        document.getElementById('new-password').classList.add('is-invalid');
        return false;
      }
      if (!this.newPassword2) {
        document.getElementById('new-password2').classList.add('is-invalid');
        return false;
      }
      if (this.newPassword !== this.newPassword2) {
        document.getElementById('new-password2').classList.add('is-invalid');
        return false;
      }

      return true;
    },
  },
};
</script>

<style></style>
