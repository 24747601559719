<template>
  <div>
    <div
      class="text-center loader"
      v-if="loader"
    >
      <spiner />
    </div>
    <div v-else>
      <div>
        <div
          style="position:relative"
          :style="menuCols"
        >
          <Sidebar />
        </div>
        <div
          class="content"
          :style="contentCols"
        >
          <Navbar />
          <div class="wrapper">
            <div class="container-dashboard">
              <router-view />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from '@/components/organisms/navbar/index.vue';
import Sidebar from '@/components/organisms/sidebar/index.vue';
import Spiner from '@/components/atoms/loader/index.vue';
import api from '../../../services/api';
import EventBus from '../../../eventBus';

export default {
  name: 'Webapp',
  components: {
    Navbar,
    Sidebar,
    Spiner,
  },

  data: () => ({
    userAuth: null,
    loader: false,
    contentCols: {
      width: 'calc(100% - 70px)',
      marginLeft: '70px',
    },
    menuCols: {
      width: '70px',
    },
  }),

  methods: {
  },

  async created() {
    // this.loader = true;
    this.userAuth = JSON.parse(localStorage.getItem('_pbiDadosVan'));
  },

  async mounted() {
    if (!this.userAuth) {
      localStorage.setItem('_pbiDadosVan', null);
      this.$router.push('/login');
    }
    // eslint-disable-next-line
    EventBus.$on('collapse', (param) => {
      if (param) {
        this.contentCols.width = 'calc(100% - 70px)';
        this.contentCols.marginLeft = '70px';
        this.menuCols.width = '70px';
      } else {
        this.contentCols.width = 'calc(100% - 200px)';
        this.contentCols.marginLeft = '200px';
        this.menuCols.width = '200px';
      }
    });
    const res = await api.post(
      '/verifyToken',
      {},
      {
        headers: {
          'x-access-token': this.userAuth.token,
        },
      },
    );
    if (!res.data.auth) {
      this.loader = false;
      this.$router.push('/login');
    // } else {
      // this.$router.push('/dashboard');
    }
  },
};
</script>

<style lang="scss">
.loader {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.app-container {
  background: -webkit-linear-gradient(0deg, #202020, #404040 100%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
  pointer-events: none;
  height: 330px;
}
.content {
  transition: all 0.2s linear;
  overflow: hidden;
}
.container-dashboard {
  background-color: #fafafa;
  height: 100vh;
  padding: 10px;
}
.v-sidebar-menu .vsm--list {
  margin-top: 0px;
}
</style>
