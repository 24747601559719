import { getField, updateField } from 'vuex-map-fields';
import api from '../../services/api';

export default {
  namespaced: true,
  state: {
    user: {},
    users: [],
    total: 0,
  },

  mutations: {
    updateField,
    SET_USER(state, payload) {
      state.user = payload;
    },
  },

  actions: {
    async getUser({ commit }, obj) {
      const { data } = await api.get(`/user/${obj.id}`, {
        headers: { 'x-access-token': obj.token },
      });

      commit('SET_USER', data);
    },
  },

  getters: { getField },
};
