<template>
  <div>
    <div class="loader" v-show="loader">
      <spinner />
    </div>
    <div v-show="!loader" id="report" class="report"></div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import axios from 'axios';
import * as pbi from 'powerbi-client';
import spinner from '@/components/atoms/loader/index.vue';

export default {
  name: 'ReportPbi',
  computed: {
    ...mapState('users', ['user']),
  },
  components: {
    spinner,
  },
  props: {
    reportId: {
      type: String,
      required: true,
    },
    groupId: {
      type: String,
      required: true,
    },
    pageName: {
      type: String,
    },
    usernameReport: {
      type: String,
    },
  },
  data: () => ({
    userAuth: null,
    loader: true,
    embedUrl: null,
    embedToken: null,
    report: null,
    container: null,
  }),
  methods: {
    ...mapActions('users', ['getUser']),
    async embedPowerBi() {
      // eslint-disable-next-line
      const models = pbi.models;
      const permissions = models.Permissions.All;

      // eslint-disable-next-line
      powerbi.embed(this.container, {
        type: 'report',
        id: this.report,
        embedUrl: `${this.embedUrl}`,
        tokenType: models.TokenType.Embed,
        accessToken: this.embedToken,
        permissions,
        settings: {
          filterPaneEnabled: true,
          navContentPaneEnabled: false,
          panes: {
            pageNavigation: {
              visible: true,
            },
          },
        },
      });
    },
  },
  async mounted() {
    this.userAuth = JSON.parse(localStorage.getItem('_pbiDadosVan'));
    await this.getUser({ id: this.userAuth.id, token: this.userAuth.token });

    this.container = document.getElementById('report');
    const url = `https://powerbi.idcx-verse.com/?reportId=${
      this.reportId
    }&groupId=${this.groupId}`;

    axios
      .get(url)
      // eslint-disable-next-line
      .then((response) => {
        this.loader = false;
        this.embedUrl = response.data.EmbedURL;
        this.embedToken = response.data.EmbedToken;
        this.report = response.data.ReportId;
        this.embedPowerBi();
      })
      .catch();
  },
};
</script>

<style lang="scss">
.report {
  height: calc(100vh - 73px);
  max-height: calc(100vh - 73px);
  iframe {
    display: block;
    border: none;
    height: 100vh;
    width: 100vw;
  }
}

.loader {
  width: 100%;
  height: calc(100vh - 95px);
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
