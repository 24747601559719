import validator from 'validator';
import types from './mutations-types';
import router from '../../router';
import api from '../../services/api';

const actions = {
  async verifyToken(
    {
      // eslint-disable-next-line
      commit,
    },
    token,
  ) {
    const res = await api.post(
      '/verifyToken',
      {},
      {
        headers: {
          'x-access-token': token,
        },
      },
    );
    return res.data;
  },
  async login({ commit }, obj) {
    const res = await api.post('/login', {
      ...obj,
    });

    if (res.data.auth && validator.isJWT(res.data.token)) {
      localStorage.setItem(
        '_pbiDadosVan',
        JSON.stringify({
          id: res.data.id,
          token: res.data.token,
          email: obj.email,
        }),
      );

      commit(types.SET_JWT, res.data);
      commit(types.SET_EMAIL, obj.email);

      router.push('/dashboard');
    }
  },
  // eslint-disable-next-line
  async logout({ commit }, token) {
    await api.post('/logout', {
      token,
    });
  },
};

export default actions;
