<template>
  <div class="wrapper-sidebar">
    <div class="brand-sidebar text-center">
      <img
        src="../../../assets/mercedes_logo.svg"
        alt=""
      />
    </div>
    <ul class="menu-list">
      <li
        v-for="(item, i) in menu"
        :key="i"
        @click="$router.push(item.link ? item.link : null)"
        :style="styleMenuItem"
        :title="item.title"
      >
        <label :for="`check-${i}`">
          <div>
            <i :class="item.icon"></i>
          </div>
          <div v-if="!collapsed">{{ item.title }}</div>
        </label>
        <input
          type="checkbox"
          :id="`check-${i}`"
          class="check"
          checked
        />
        <ul>
          <li
            v-for="(li, c) in item.children"
            :key="c"
            @click="$router.push(li.link)"
            class="item-submenu"
          >
            <span><i class="fa fa-chart-area collapsed"></i></span>
            <span>{{ li.title }}</span>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import EventBus from '../../../eventBus';

export default {
  name: 'Sidebar',
  data: () => ({
    userAuth: null,
    collapsed: true,
    styleMenuItem: { 'justify-content': 'center' },
    menu: [
      {
        title: 'Dashboard',
        icon: 'fa fa-chart-area',
        link: '/dashboard',
      },
      {
        title: 'Preco',
        icon: 'fa fa-dollar-sign',
        link: '/preco',
      },
    ],
    dropMenu1: false,
    dropMenu2: false,
    dropMenu3: false,
  }),

  computed: {
    ...mapState('users', ['user']),
  },
  methods: {
    ...mapActions('users', ['getUser']),
    async collapse(param) {
      this.collapsed = param;
      if (param) {
        this.styleMenuItem = {
          'justify-content': 'center',
        };
        const items = document.querySelectorAll('.item-submenu');

        if (items.length > 0) {
          // eslint-disable-next-line no-restricted-syntax
          for (const obj in items) {
            if (items[obj]) items[obj].classList.add('collapsed');
          }
        }
      } else {
        this.styleMenuItem = {};

        const items = document.querySelectorAll('.item-submenu');
        if (items.length > 0) {
          // eslint-disable-next-line no-restricted-syntax
          for (const obj in items) {
            if (items[obj]) items[obj].classList.remove('collapsed');
          }
        }
      }
    },
  },
  async created() {
    this.userAuth = JSON.parse(localStorage.getItem('_pbiDadosVan'));
  },
  async mounted() {
    await this.getUser({ id: this.userAuth.id, token: this.userAuth.token });
    // this.collapse(true);
    EventBus.$on('collapse', (param) => {
      this.collapse(param);
    });
  },
};
</script>

<style lang="scss" scoped>
.wrapper-sidebar {
  width: 100%;
  max-width: 200px;
  height: 100vh;
  position: absolute;
  background-color: #303030;
  color: #fff;
  transition: all 0.2s linear;
  .brand-sidebar {
    position: relative;
    margin-top: 10px;
    padding: 5px 8px;
    img {
      width: 100%;
      max-width: 70px;
    }
  }
  .menu-list {
    color: #fff;
    padding-left: 0;
    list-style: none;
    margin-top: 30px;
    width: 100%;
    a {
      color: #fff;
      text-decoration: none;
    }
    > a li,
    > li {
      cursor: pointer;
      width: 100%;
      display: block;
      padding: 5px;
      &:hover {
        background-color: rgba(30, 30, 33, 0.5);
      }
      div {
        cursor: pointer;
        &:first-of-type {
          background-color: #1e1e21;
          align-items: center;
          justify-content: center;
          width: 30px;
          height: 30px;
          margin: 5px;
          border-radius: 3px;
          display: inline-block;
          text-align: center;
          padding-top: 3px;
        }
        &:last-of-type {
          margin: 5px;
          border-radius: 3px;
          display: inline-block;
        }
      }
      ul {
        overflow: hidden; // Hide the element content, while height = 0
        height: 0;
        opacity: 0;
        transition: height 0ms 400ms, opacity 400ms 0ms;
        list-style: none;
        padding-left: 0;
        li {
          padding: 10px 15px 10px 30px;
          &:hover {
            background-color: rgba(0, 0, 0, 0.2);
          }
          span {
            &:first-of-type {
              display: none;
            }
            &:last-of-type {
              display: block;
            }
          }
        }

        li.collapsed {
          width: 100%;
          padding: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          span {
            &:first-of-type {
              display: block;
            }
            &:last-of-type {
              display: none;
            }
          }
          &:hover {
            background-color: rgba(0, 0, 0, 0.2);
          }
        }
      }
      .check {
        display: none;
        &:checked {
          ~ ul {
            height: auto;
            opacity: 1;
            transition: height 0ms 0ms, opacity 600ms 0ms;
          }
        }
      }
    }
  }
}
</style>
